<template>

  <div class="form-container">
      <p id="form-login-error" class="error"  v-if="getFormError()">
        <span id="formError">{{ getFormError() }}</span>
      </p>
      <p id="form-login-error-empty" class="error" v-else>
      </p>
  <form class="form-user" @submit.prevent="logIn()">
    <fieldset class="form-box">
      <div class="row plain-element center-align">
        <h3 class="underline-green">Login</h3>
      </div>
      <div class="row text-center row-form">
        <div class="row plain-element">
          <div class="input-field  col col-12 col-sm-12">
            <input type="text" name="username" v-model="username" id="id_username" placeholder="Username/Email" required>
          </div>
        </div>
        <div class="row plain-element">
          <div class="input-field  col col-12 col-sm-12">
            <input type="password" name="password" v-model="password" id="id_password" placeholder="Password" required>
          </div>
        </div>
        <a id="forgot-password" href="/auth/reset-password-request/">Forgot Password?</a>

      </div>
      <button type="submit" class="btn-login"><span>Login</span>
      </button>

    </fieldset>
  </form>

</div>

</template>




<script>

import { mapGetters, mapActions } from 'vuex';
import { apiService } from "@/common/api.service.js";

export default {
  name: "LoginView",
  components: {

  },
  data() {
    return {
        username: null,
        password: null,
    }
  },
  methods: {
    ...mapGetters(["getLoginData", "getUsername","login", "setUserInfo", "getFormError", "updateLoginData"]),
    ...mapActions(["updateLoginData", "performSetFormError", "updateQrCode"]),
    showError(error) {
      this.performSetFormError(error)
    },
    logIn() {
      if (!this.username || !this.password ) {
          this.showError("Fields can't be empty.");
      }
      else {
        let endpoint = "/auth/user-login/";
        apiService(endpoint, "POST", {"username": this.username, "password": this.password })
          .then(data => {
            if (data) {
              if (data.username) {
                this.showError(data.username[0]);
              }
              else if (data.non_field_errors) {
                this.showError(data.non_field_errors[0]);
              }
              else if (data.password1) {
                this.showError(data.password1[0]);
              }
              else if (data.detail) {
                this.showError(data.detail);
              }
              else if (data.change_password_id) {
                location.href=(`/auth/change-password/${data.change_password_id}/`);
              }
              else if (data.tfa_otpauth_url) {
                this.updateQrCode(data.tfa_otpauth_url)
                this.$router.push({ name: 'mfa-setup' });
//                location.href=(`/mfa-setup/`);
              }
              else if (data.login_credentials) {
                location.href=(`/auth/mfa-code/${data.user_id}/`);
              }
              else {
                location.href=(`/`);
              }
            }
        })

      }
    }


  },
  created() {
    document.title = "Auth";
  }
};
</script>
