<template>
    <div id="app">
      <router-view/>
    </div>
</template>

<script>
import { mapActions, } from 'vuex';

export default {
    name: "App",
    components: {
    },
    methods: {
      ...mapActions(['getUserInfo', 'getCompanyInfo','fetchSiteNames', "fetchUserPermissions", "fetchSiteCompanyNames",
      "fetchUserSites", 'fetchCurrencies', 'fetchUserServiceSites',  'fetchUserSiteServices']),
    },
    computed: {

    },
    created() {
        this.fetchSiteNames();
        this.fetchSiteCompanyNames();
        this.getUserInfo();
        this.getCompanyInfo();
        this.fetchUserSites();
        this.fetchUserServiceSites();
        this.fetchCurrencies();
        this.fetchUserSiteServices();
    }
}
</script>

