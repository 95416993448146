import { apiService } from "@/common/api.service.js";
import axios from 'axios';
import { CSRF_TOKEN } from "@/common/csrf_token.js"

export default {
    companySites(company) {
        let endpoint = `/api/company-sites/${company}/`;
        return apiService(endpoint);
    },
// Get Companies
    companies() {
        let endpoint = `/api/companies/`;
        return apiService(endpoint);
    },
// Get Currencies
  currencies() {
    let endpoint = `/api/currencies/`;
    return apiService(endpoint);
  },
// Get current user information
  userInfo() {
    let endpoint = "/auth/current-user/";
    return apiService(endpoint);
  },
// Get user profile
  userProfile() {
    let endpoint = "/auth/profile/";
    return apiService(endpoint);
  },
// Create user profile
  createUserProfile() {
    let endpoint = "/auth/create-profile/";
    return apiService(endpoint, "POST");
  },
// Change user profile
  updateUserProfile(payload) {
    let endpoint = "/auth/profile/";
    return apiService(endpoint, "POST", payload);
  },
  //  Get user dashboard sites
  userSites() {
    let endpoint = "/auth/user-sites/";
    return apiService(endpoint);
  },

//  Get site permissions
  userPermissions(id) {
    let endpoint = `/auth/user-permissions/${id}/`;
    return apiService(endpoint);
  },

//  Get user list
  userList() {
    let endpoint = "/auth/user-list/";
    return apiService(endpoint);
  },

// Update site permissions
  updateSitePermissions(payload) {
    let endpoint = "/auth/update-permissions/";
    return apiService(endpoint, "POST", payload);
  },


// Get company information
  companyInfo() {
    let endpoint = "/company";
    return apiService(endpoint);
  },

// Get all opportunities
  getAllOpportunities() {
    let endpoint = `/api/opportunity-all/`;
    return apiService(endpoint);
  },
// Search opportunity query
  searchOpportunityQuery(searchQuery) {
    let endpoint = `/api/opportunities/?search=${searchQuery}`;
    return apiService(endpoint);
  },
// Get Site opportunities
  siteOpportunities(site) {
    let endpoint = `/api/opportunities/?site_code__id=${site}`;
    return apiService(endpoint);
  },
// Get Site opportunities
  siteCodeOpportunities(site) {
    let endpoint = `/api/opportunities/?site_code__site_code=${site}`;
    return apiService(endpoint);
  },
// Get Seu opportunities
  seuOpportunities(seu) {
    let endpoint = `/api/opportunities/?seu__id=${seu}`;
    return apiService(endpoint);
  },
// Get opportunity details
  opportunityData(opportunityId) {
    let endpoint = `/api/opportunities/${opportunityId}/`;
    return apiService(endpoint);
  },
// Get opportunity id
  opportunityId(opportunity_number) {
    let endpoint = `/api/opportunity-id/${opportunity_number}/`;
    return apiService(endpoint);
  },
// Get status details
  opportunityStatusDetails(opportunityId) {
    let endpoint = `/api/opportunity-status-details/${opportunityId}/`;
    return apiService(endpoint);
  },
// Change status details
  changeOpportunityStatusDetails(payload) {
    let endpoint = `/api/opportunity-status-details/${payload.opportunity_id}/`;
    return apiService(endpoint, "POST", payload);
  },
// Create Opportunity
  opportunityCreate(payload) {
    let endpoint = `/api/opportunity-create/`;
    return apiService(endpoint, "POST", payload)
  },
// Update Opportunity
  opportunityUpdate(payload) {
    let endpoint = `/api/opportunity-update/${payload.id}/`;
    return apiService(endpoint, "PATCH", payload);
  },
// Change Opportunity Status
  changeOpportunityStatus(payload) {
    let endpoint = `/api/opportunity-status/${payload.id}/`;
    return apiService(endpoint, "POST", payload)
  },
// Create Opportunity Log Entry
  opportunityLogEntryCreate(payload) {
    let endpoint = `/api/opportunity-logs/`;
    return apiService(endpoint, "POST", payload);
  },
// Create Predecessor relation
  createPredecessorRelation(payload) {
    let endpoint = `/api/predecessor-relation/${payload.id}`;
    return apiService(endpoint, "POST", payload)
  },
// Create Conflict relation
  createConflictRelation(payload) {
    let endpoint = `/api/conflict-relation/${payload.id}`;
    return apiService(endpoint, "POST", payload)
  },
// Opportunity Comments
  opportunityComments(opportunityNumber) {
    let endpoint = `/api/opportunity-comment/${opportunityNumber}`;
    return apiService(endpoint);
  },
// Add Comment
  createOpportunityComment(payload) {
    let endpoint = `/api/opportunity-comment/${payload.opportunity_number}`;
    console.log(endpoint)
    console.log(payload)
    return apiService(endpoint, "POST", payload);
  },


// Sites and Seu
  siteNames() {
    let endpoint = `/api/site-names/`;
    return apiService(endpoint);
  },
  siteCompanyNames() {
    let endpoint = `/api/site-company-names/`;
    return apiService(endpoint);
  },
  siteServices(payload)  {
    let endpoint = `/api/site-service-list/${payload.site_code}/`;
    return apiService(endpoint);
  },
  // Update site services
  updateSiteServices(payload) {
    let endpoint = `/api/site-service-list/${payload.site_code}/`;
    return apiService(endpoint, "POST", payload);
  },
  sitesAndSeu() {
    let endpoint = `/api/sites-and-seu/`;
    return apiService(endpoint);
  },
  sitesAndSeuAndOpp() {
    let endpoint = `/api/sites-and-seu-and-opp/`;
    return apiService(endpoint);
  },
// Sites present in opportunities queryset
  opportunitiesSites() {
    let endpoint = `/api/opportunities-sites/`;
    return apiService(endpoint);
  },
// Site View
  siteView(id) {
    let endpoint = `/api/site/${id}/`;
    return apiService(endpoint);
  },

// Helper View to get Site specific seu for navigation
  siteSeuList(id) {
    let endpoint = `/api/site-seu/${id}/`;
    return apiService(endpoint);
  },
// Seu View
  siteSeuView(site_id, seu_id) {
    let endpoint = `/api/seu/${site_id}/${seu_id}/`;
    return apiService(endpoint);
  },
// Nested Site Seu for filters
  nestedSitesAndSeu() {
    let endpoint = `/api/nested-site-seu/`;
    return apiService(endpoint);
  },
  // Full Service Site Seu for filters
  serviceSitesAndSeu() {
    let endpoint = `/api/service-site-seu/`;
    return apiService(endpoint);
  },
// Nested Company Sites
  nestedCompanySites() {
    let endpoint = `/api/nested-company-sites/`;
    return apiService(endpoint);
  },
// Get Opportunity Number
  opportunityNumber(id) {
    let endpoint = `/api/get-opportunity-number/${id}/`
    return apiService(endpoint);
  },
  nestedSitesAndDashboardDetails() {
    let endpoint = `/api/nested-site-dashboard-details/`;
    return apiService(endpoint);
  },


// Get all actions
  getAllActions() {
    let endpoint = `/api/action-all/`;
    return apiService(endpoint);
  },
// Search action query
  searchActionQuery(searchQuery) {
    let endpoint = `/api/actions/?search=${searchQuery}`;
    return apiService(endpoint);
  },
// Get action details
  actionData(actionId) {
    let endpoint = `/api/actions/${actionId}/`;
    return apiService(endpoint);
  },
// Create Action
  actionCreate(payload) {
    let endpoint = `/api/action-create/`;
    return apiService(endpoint, "POST", payload)
  },
// Update Action
  actionUpdate(payload) {
    let endpoint = `/api/action-update/${payload.id}/`;
    return apiService(endpoint, "PATCH", payload);
  },
// Change Action Status
  changeActionStatus(payload) {
    let endpoint = `/api/action-status/${payload.id}/`;
    return apiService(endpoint, "POST", payload)
  },
// Change Action Graph Type
  changeActionGraphType(payload) {
    let endpoint = `/api/action-graph/${payload.id}/`;
    return apiService(endpoint, "POST", payload)
  },
// Create Action Log Entry
  actionLogEntryCreate(payload) {
    let endpoint = `/api/action-logs/`;
    return apiService(endpoint, "POST", payload);
  },
// Generate Latex Report for download
  generateReport() {
    let endpoint =`/latex-reports/generate-report/`;
    return apiService(endpoint);
  },

// Action Types
  actionTypes() {
    let endpoint = `/api/action-types/`;
    return apiService(endpoint);
  },
// Event Types
  eventTypes() {
    let endpoint = `/api/event-types/`;
    return apiService(endpoint);
  },
// Cost Intervals
  costIntervals() {
    let endpoint = `/api/cost-intervals/`;
    return apiService(endpoint);
  },
// Opportunity Status
  opportunityStatusList() {
    let endpoint = `/api/opportunity-status-list/`;
    return apiService(endpoint);
  },
// Create Enpi-Action relation
  createEnpiActionRelation(payload) {
    let endpoint = `/api/enpi-action/${payload.id}`;
    return apiService(endpoint, "POST", payload)
  },
// Create Opportunity-Action relation
createOpportunityActionRelation(payload) {
    let endpoint = `/api/opportunity-action/${payload.id}`;
    return apiService(endpoint, "POST", payload)
  },


// Action Comments
  actionComments(actionNumber) {
    let endpoint = `/api/action-comment/${actionNumber}`;
    return apiService(endpoint);
  },
// Add Comment
  createActionComment(payload) {
    let endpoint = `/api/action-comment/${payload.action_number}`;
    console.log(endpoint)
    console.log(payload)
    return apiService(endpoint, "POST", payload);
  },

// NestedEnpi

  nestedEnpi() {
    let endpoint = `/api/nested-enpi/`;
    return apiService(endpoint);
  },
  siteSeuEnpi(payload) {
    let endpoint = `/api/enpi/?site_code__id=${payload.site_id}&seu__id=${payload.seu_id}`;
    return apiService(endpoint);
  },
  siteEnpi(payload) {
    let endpoint = `/api/enpi/?site_code__id=${payload.site_id}`;
    return apiService(endpoint);
  },
  enpiCreate(payload) {
    let endpoint = `/api/enpi-create/`;
    return apiService(endpoint, "POST", payload)
  },

// Power Bi
  SEUDashboard(payload) {
    let endpoint = `/power-bi/seu-dashboard/`;
    return apiService(endpoint, "POST", payload);
  },
  powerBIDashboards() {
      let endpoint = `/api/nested-site-dashboards/`;
      return apiService(endpoint);
  },
  energyEyeDashboards() {
      let endpoint = `/api/nested-energyeye-dashboards/`;
      return apiService(endpoint);
  },


//
// CAPACITY TOOL
//

// Nested Rated Capacities for Filtering
  nestedRatedCapacities() {
    let endpoint = `/api/nested-rated-capacities/`;
    return apiService(endpoint);
  },
  getCapacity(payload) {
    let endpoint = `/api/get-capacity/`;
    return apiService(endpoint, "POST", payload)
  },

//
// METRICS
//

  eeosRates(payload) {
    let endpoint = `/metrics/eeos-rate/?site_code__site_code=${payload.site}`;
    return apiService(endpoint);
  },


//
// PDF REPORTS
//

  getAllPdfReports() {
    let endpoint = `/documents/pdf-reports`;
    return apiService(endpoint);
  },
  permittedSites() {
      let endpoint = `/documents/pdf-report-create/`;
      return apiService(endpoint);
  },

// Create PDF Report
  pdfReportCreate(payload) {
    const formData = new FormData();
    formData.append("site_code", payload.site_code);
    formData.append("project_number", payload.project_number);
    formData.append("pdf_report", payload.pdf_report);
    formData.append("iso_chapter", payload.iso_chapter);
    formData.append("author", payload.author);
    formData.append("document_title", payload.document_title);
    formData.append("filename", payload.filename);

    return axios.post("/documents/pdf-report-create/", formData,
      { headers: { 'Content-Type': undefined,'X-CSRFTOKEN': CSRF_TOKEN} }
    )
  },

//
// Long-Range PLANS
//

// Sites present in plans queryset
  plansSites() {
    let endpoint = `/api/plans-sites/`;
    return apiService(endpoint);
  },
  archivePlansSites() {
    let endpoint = `/api/archive-plans-sites/`;
    return apiService(endpoint);
  },
// Companies present in plans queryset
  plansCompanies() {
    let endpoint = `/api/plans-companies/`;
    return apiService(endpoint);
  },
// Get all Site Plans
  getAllSitePlans() {
    let endpoint = `/api/site-plan-all/`;
    return apiService(endpoint);
  },
// Get Site Plans
  sitePlans(site) {
    let endpoint = `/api/site-plans/?site_code__id=${site}`;
    return apiService(endpoint);
  },
// Create Site Plan
  sitePlanCreate(payload) {
    let endpoint = `/api/site-plan-create/`;
    return apiService(endpoint, "POST", payload)
  },
// Update Site Plan
  sitePlanUpdate(payload) {
    let endpoint = `/api/site-plan-update/${payload.id}/`;
    return apiService(endpoint, "PATCH", payload);
  },
// Create Plan-Opportunity relation
  createOpportunityPlanRelation(payload) {
    let endpoint = `/api/opportunity-plan-create/${payload.id}/`;
    return apiService(endpoint, "POST", payload)
  },
// Reorder Plan Opportunities
  updatePlanDates(payload) {
    let endpoint = `/api/update-dates/${payload.id}/`;
    return apiService(endpoint, "POST", payload)
  },
// Get plan details
  sitePlanData(actionId) {
    let endpoint = `/api/site-plans/${actionId}/`;
    return apiService(endpoint);
  },
// Copy Site Plan
  sitePlanCopy(payload) {
      let endpoint = `/api/site-plan-copy/`;
      return apiService(endpoint, "POST", payload);
  },
// Waterfall Chart Data
  waterfallOpportunities() {
      let endpoint = `/api/waterfall-data/`;
      return apiService(endpoint);
  },

// Get all Corporate Plans
  getAllCorporatePlans() {
    let endpoint = `/api/corporate-plan-all/`;
    return apiService(endpoint);
  },
// Get Corporate Plans
  corporatePlans(company) {
    let endpoint = `/api/corporate-plans/?company=${company}`;
    return apiService(endpoint);
  },
// Create Corporate Plan
  corporatePlanCreate(payload) {
      let endpoint = `/api/corporate-plan-create/`;
      return apiService(endpoint, "POST", payload)
  },
// Get plan details
  corporatePlanData(actionId) {
    let endpoint = `/api/corporate-plans/${actionId}/`;
    return apiService(endpoint);
  },
// Update Corporate Plan
  corporatePlanUpdate(payload) {
      let endpoint = `/api/corporate-plan-update/${payload.id}/`;
      return apiService(endpoint, "PATCH", payload);
  },
// Corporate Site Plans
  corporateSitePlans(payload) {
      let endpoint = `/api/attach-site-plans/corporate/${payload.id}/${payload.company}/`;
      return apiService(endpoint);
  },
// Create Company - Site Plan  relation
  createCorporateSitePlanRelation(payload) {
      let endpoint = `/api/corporate-site-plan-create/${payload.id}/`;
      return apiService(endpoint, "POST", payload)
  },

// Get all Division Plans
  getAllDivisionPlans() {
    let endpoint = `/api/division-plan-all/`;
    return apiService(endpoint);
  },
// Get Division Plans
  divisionPlans(company) {
    let endpoint = `/api/division-plans/?company=${company}`;
    return apiService(endpoint);
  },
// Create Division Plan
  divisionPlanCreate(payload) {
      let endpoint = `/api/division-plan-create/`;
      return apiService(endpoint, "POST", payload)
  },
// Get plan details
  divisionPlanData(planId) {
    let endpoint = `/api/division-plans/${planId}/`;
    return apiService(endpoint);
  },
// Update Division Plan
  divisionPlanUpdate(payload) {
      let endpoint = `/api/division-plan-update/${payload.id}/`;
      return apiService(endpoint, "PATCH", payload);
  },
// Division Site Plans
  divisionSitePlans(payload) {
      let endpoint = `/api/attach-site-plans/division/${payload.id}/${payload.company}/`;
      return apiService(endpoint);
  },
// Create Division - Site Plan  relation
  createDivisionSitePlanRelation(payload) {
      let endpoint = `/api/division-site-plan-create/${payload.id}/`;
      return apiService(endpoint, "POST", payload)
  },

// Carbon Tracker Info
  sitePlanCarbonTracker(payload) {
    let endpoint = `/api/site-plan-carbon-tracker/${payload.id}/`;
    return apiService(endpoint)
  },
// Update Carbon Tracker
  sitePlanCarbonTrackerUpdate(payload) {
      let endpoint = `/api/site-plan-carbon-tracker/${payload.id}/`;
      return apiService(endpoint, "POST", payload)
  },
// Carbon Tracker Info
  divisionPlanCarbonTracker(payload) {
    let endpoint = `/api/division-plan-carbon-tracker/${payload.id}/`;
    return apiService(endpoint)
  },
// Update Carbon Tracker
  divisionPlanCarbonTrackerUpdate(payload) {
      let endpoint = `/api/division-plan-carbon-tracker/${payload.id}/`;
      return apiService(endpoint, "POST", payload)
  },
// Carbon Tracker Info
  corporatePlanCarbonTracker(payload) {
    let endpoint = `/api/corporate-plan-carbon-tracker/${payload.id}/`;
    return apiService(endpoint)
  },
// Update Carbon Tracker
  corporatePlanCarbonTrackerUpdate(payload) {
      let endpoint = `/api/corporate-plan-carbon-tracker/${payload.id}/`;
      return apiService(endpoint, "POST", payload)
  },


//
// SITE DASHBOARDS
//

//  Site Dashboards
   userOverallView() {
    let endpoint = `/site-dashboard/my-dashboard/`;
    return apiService(endpoint);
   },

//
// ALARMS
//

  getSearchAlarms() {
    let endpoint = `/api/alarms-search/`;
    return apiService(endpoint);
  },
  getAllAlarms() {
    let endpoint = `/api/alarms-all/`;
    return apiService(endpoint);
  },

  searchAlarmQuery(searchQuery) {
    let endpoint = `/api/alarms/?search=${searchQuery}`;
    return apiService(endpoint);
  },

  alarmCreate(payload) {
    let endpoint = `/api/alarm-create/`;
    return apiService(endpoint, "POST", payload)
  },

  alarmData(alarmId) {
    let endpoint = `/api/alarms/${alarmId}/`;
    return apiService(endpoint);
  },

  alarmUpdate(payload) {
    let endpoint = `/api/alarm-update/${payload.id}/`;
    return apiService(endpoint, "PATCH", payload);
  },

  nestedSitesAndAlarms() {
    let endpoint = `/api/nested-site-alarms/`;
    return apiService(endpoint);
  },

//
// EVA
//
  startEvaSession() {
    let endpoint = `/api/start-session/`;

    return apiService(endpoint, "POST");
  },
  startEvaOpportunitiesSession() {
    let endpoint = `/api/start-session-opportunities/`;

    return apiService(endpoint, "POST");
  },
  askEvaQuestion(payload) {
    let endpoint = `/api/ask-question/${payload.sessionId}/`;

    return apiService(endpoint, "POST", payload);
  },
  evaAnnouncements() {
    let endpoint = `/api/eva-announcements/`;
    return apiService(endpoint);
  },
//  Pagination
  nextPage(next) {
    return apiService(next);
  }
}