import qrcode from "qrcode";

const state = {
  loginData: {"id": 0},
  qrCode: null,
};

const getters = {
  getLoginData: state => state.loginData,
  getQrCode: state => state.qrCode,
};


const actions = {
  updateLoginData({commit}, payload) {
    commit("setLoginData", payload);
  },
  updateQrCode({commit}, payload) {
    var image = null
    qrcode.toDataURL(payload, (err, data) => {
      image = data;
    });
    commit("setQrCode", image);
  }
};

const mutations = {
  setLoginData: (state, loginData) => {
    state.loginData = loginData;
  },
  setQrCode: (state, qrCode) => {
    state.qrCode = qrCode;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}